
import React ,{useState,useEffect, useRef} from "react"
import { search_ApiManager, view_ApiManager } from "../ApiManager/ApiManager";
import Title from "../GenericComponents/Title";
import { useParams } from "react-router";
import { Sorting } from "../GenericComponents/SortTables";
import { eduStatus, visaStatus } from "../../utilis/appStatus";
import MultipleSearchs from "../GenericComponents/MultipleSearchs";
import { Link } from "react-router-dom";
import moment from "moment";
import Paginations from "../GenericComponents/Pagination";

const ExternalApps = () => {
    const { type } = useParams();
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [lastPage, setLastPage] = useState('');
    const [totalItem, setTotalItem] = useState('');
    const [search, setSearch] = useState('');
    const [allApps, setAllApps] = useState([]);
    const [Index, setIndex] = useState('');
    const [perItem, setPerItem] = useState('');
    const [allUsers, setAllUsers] = useState([]);
    const [order, setOrder] = useState("ASC");
    const [client, setClient] = useState('');
    const [appType, setAppType] = useState('');
    const [njdue, setNjDue] = useState('');
    const [status, setStatus] = useState([]);
    const [institute, setInstitute] = useState('');
    const [counselor, setCounselor] = useState([]);
    const [logedDate, setLogedDate] = useState(false);
    const [filteredStatus, setFilteredStatus] = useState([]);
    const [headers, setHeaders] = useState([ 
        {title: '#' , dataKey: "index", align: "text-center", key:1, checked: true, canChange: false},
        {title: 'Client' , dataKey: "clientname", align: "text-center", key:2, checked: true, canChange: false},
        {title: 'Category', dataKey: 'categoryname', align:"text-center col-2", key:3, checked: true, canChange: true},
        {title: 'Provider', dataKey: 'providername', align:"text-center", key:4, checked: true, canChange: false},
        {title: (type === 'mymig' || type === 'mig') ? 'App Type': 'Course/Package' , dataKey: 'coursename', align:"text-center", key:5, checked: true, canChange: false},
        {title: 'Date Lodged', dataKey: 'datelodged', align:"text-center col-3", key:6, checked: true, canChange: true},
        {title: 'NJ Due' , dataKey: "njdate", align:"text-center", key:7, checked: true, canChange: true},
        {title: 'RFI Due' , dataKey: "rfidate", align:"text-center", key:8, checked: true, canChange: true},
        {title: 'Status' , dataKey: "status", align:"text-center", key:9, checked: true, canChange: true},
        {title: 'CreatedAt' , dataKey: "created_at", align:"text-center", key:10, checked: true, canChange: true},
        {title: 'Assigned To' , dataKey: "assignedTo", align:"text-center", key:11, checked: true, canChange: true},
        {title: 'Last Activity', dataKey: "last_activity", align:"text-center", key:13, checked: true, canChange: true},
        {title: 'External Referring Agent' , dataKey: "external_referring_agent", align:"text-center", key:14, checked: true, canChange: false},
    ]);

    useEffect( () => {
        if(!search){
            getExternalApps();
        }
    }, [currentPage, perPage]);

    useEffect( () => {
       getAllUsers();
       filterUniqueStatus();
    }, []);

    const getExternalApps = async () => {
        setLoading(true);
        let url = `externalApps/${type}`;
        console.log(url, perPage, currentPage);
        view_ApiManager(url, currentPage, perPage,(res) => {
            console.log('external-apps',res);
            setAllApps(res.data.data['data']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setIndex(currentPage * perPage - perPage + 1);
            setLoading(false);
         
        },(err) => {
            console.log(err);
        });
    }
    const sorting = (col) => {
        const sortResult = Sorting(allApps, col, order)
        setOrder(sortResult[0]);
        setAllApps(sortResult[1]);
     }
     const handleColumnsCallback = (selectedColumns) => {
        setHeaders(selectedColumns);
     }
     const handleSearchCallback = (input1, input2, input3, input4, input5, input6, input7, input8) => {
        let Client, Institute, AppType, DateLogded, Status, NjDue, Counselor;
        if (input1 === 'clear') {
            setSearch('');
          } else if (input1 === 'yes') {
            Client = client;
            Institute = institute;
            AppType = appType;
            DateLogded = logedDate;
            NjDue = njdue;
            Status = status;
            Counselor = counselor;
          } else {
            setSearch('yes');
            Client = input1;
            Institute = input2;
            AppType = input3;
            DateLogded = input4;
            NjDue = input5;
            Status = input6;
            Counselor = input7;
            setClient(Client);
            setInstitute(Institute);
            setAppType(AppType);
            setLogedDate(DateLogded);
            setStatus(Status);
            setNjDue(NjDue);
            setCounselor(Counselor);
          }
          const data = {
            client: Client,
            institute: Institute,
            apptype: AppType,
            dateloged: DateLogded,
            status: Status,
            njdue: NjDue,
            counselor: Counselor,
            type: type
          };
          console.log(data)
          let url = `search/externalApps`;
          search_ApiManager(url, data, currentPage, perPage, (res) => {
            console.log('search result', res);
            setAllApps(res.data.data['data']);
            setPerPage(res.data.data['per_page']);
            setLastPage(res.data.data['last_page']);
            setTotalItem(res.data.data['total']);
            setPerItem(res.data.data['data'].length);
            setIndex(currentPage * perPage - perPage + 1);
          }, (err) => {
            console.log(err);
          });
    };
    const getAllUsers = async () => {
        let url = 'getAllUsers';
        view_ApiManager(url, 0, 0,(res) => {
            console.log('Get all Users',res);
            setAllUsers(res.data.data);
        },(err) => {
            console.log(err);
        });
     }
     const handlePaginationCallback = (i, perPage) => {
        if(perPage && perPage != ''){
            setCurrentPage(1);
            setPerPage(perPage); 
        } else { setCurrentPage(i);  }
     };
     const filterUniqueStatus = () => {
        const uniqueStatus = eduStatus.reduce((unique, item) => {
          if (!unique.some(obj => obj.title === item.title)) {
            unique.push(item);
          }
          return unique;
        }, []);
        setFilteredStatus(uniqueStatus);
     };

    return(
        <>
        <Title title={'External Applications'} back={true} />
        <div className="row w-100 pl-3 pr-3 mb-5">
        <MultipleSearchs
         input1={headers[1]['checked'] && 'Search Client'}
         input1_type={"input"}
         input2={headers[3]['checked'] && 'Search Institute'}
         input2_type={"input"}
         input3={headers[4]['checked'] && 'Search App Type'}
         input3_type={"input"}
         input4={headers[5]['checked'] && 'Search Date Lodged'}
         input4_type={"date"}
         input5={headers[6]['checked'] || headers[7]['checked']  ? 'Search NJ/RFI Due': ''}
         input5_type={"date"}
         input6={headers[8]['checked'] && 'Select Status'}
         input6_type={"dropdown"}
         input6_multiple={"yes"}
         input6Data={(type === 'mymig' ||  type === 'mig') ? visaStatus : filteredStatus}
         input7={'Select AssignedTo'}
         input7_type={"dropdown"}
         input7_multiple={"yes"}
         input7Data={allUsers}
         onSearchChange={handleSearchCallback}
         onColumnsChange={handleColumnsCallback}
         allColumns={headers}
         />
        <div>
        <table className="table table-bordered bg-white table-responsive">
        <thead className="thead-dark">
        <tr>
        {headers.map(head => (
         head.checked &&
        <th id={head.dataKey} className={head.align} onClick={() => sorting(head.dataKey)} key={head.key}>
        {head.title}</th>
        ))}
        </tr>
        </thead>
        <tbody>
        {allApps.map((value, index) =>
        <tr>
        {headers[0]['checked'] && <td className="text-center">{Index +index}</td>}
        {headers[1]['checked'] && <td className="text-center">
        <Link to={`/clients/view-client-detail/${value.clientid}`}>{value.clientname}</Link></td>}
        {headers[2]['checked'] && <td className="text-center">{value.categoryname}</td>}
        {headers[3]['checked'] && <td className="text-center name-text">{value.providername}</td>}
        {headers[4]['checked'] && <td className="text-center">
        {(type === 'mymig' || type === 'mig') ?
            <Link to={`/applications/view-migration-application-detail/${value.appid}`}>{value.coursename}</Link>
            :
            <Link to={`/applications/view-education-application-detail/${value.appid}`}>
            {(value.coursetype == "single") ? value.coursename : value.packagename}</Link>
            }
        </td>}
        {headers[5]['checked'] && <td className="text-center"> {value.datelodged}</td>}
        {headers[6]['checked'] && <td className="text-cente w-10">{value.njdate ? value.njdate : '--'}</td>}
        {headers[7]['checked'] && <td className="text-center w-15">{value.rfidate ? value.rfidate : '--'}</td>}
        {headers[8]['checked'] && <td className="text-center w-15">{value.status}</td>}
        {headers[9]['checked'] && <td className="text-center w-10">{moment(value.created_at).format('D MMM YYYY')}</td>}
        {headers[10]['checked'] && <td className="text-center w-10">{value.assignedTo ? value.assignedTo : 'Unassigned'}</td>}
        {headers[11]['checked'] && <td className="text-center w-10">
        <small>{moment(value.last_activity).format('D MMM YYYY')}</small>
        </td>}
        {headers[12]['checked'] && <td className="text-center w-10">
        <small>{value.external_referring}</small>
        </td>}
        </tr>
        )}
        </tbody>
        </table>
        </div>
        <Paginations
            currentPage={currentPage}
            total= {totalItem}
            itemsPerPage= {perPage}
            lastPage={lastPage}
            totalItems={totalItem}
            perItem={perItem}
            onPageChange= {handlePaginationCallback} 
        />
        </div>
        </>
        )
}
export default ExternalApps