
export const migrationType = [
    {
        noicc : 'Notice of Intention to Consider Cancellation (NOICC) of Visa',
        eoi : 'Expression of Interest (EOI)',
        eoi_189 : 'Expression of Interest (EOI) - 189',
        eoi_190 : 'Expression of Interest (EOI) - 190',
        eoi_491 : 'Expression of Interest (EOI) - 491',
        roi :'Registration of Interest (ROI)',
    }
]
export const gstbycountry = [
    {
        title   : 'AUD',
        value   : 10,
    },
    {
        title   : 'USD',
        value   : 18,
    },
    {
        title   : 'PKR',
        value   : 17,
    },
    {
        title   : 'CAD',
        value   : 5,
    },
    {
        title   : 'MYR',
        value   : 10,
    },
    {
        title   : 'GBP',
        value   : 20,
    },
    {
        title   : 'SGD',
        value   : 7,
    },
]
export const visaStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'Application Lodged',
        value   : 3,
    },
    {
        title   : 'RFI Received',
        value   : 4,
    },
    {
        title   : 'RFI Submitted',
        value   : 5,
    },
    {
        title   : 'Natural Justice Received',
        value   : 6,
    },
    {
        title   : 'Natural Justice Submitted',
        value   : 7,
    },
    {
        title   : 'Invalid Application',
        value   : 12,
    },
    {
        title   : 'Discontinued',
        value   : 13,
    },
    {
        title   : 'Application Approved',
        value   : 100,
    },
    {
        title  : 'Application Refused',
        value   : 101,
    },
    {
        title  : 'Application Withdrawn',
        value   : -1,
    },
    
]
export const rfiStatus = [
    {
        title   : 'RFI Received',
        value   : 4,
    },
    {
        title   : 'RFI Submitted',
        value   : 5,
    },  
]
export const njStatus = [
    {
        title   : 'NJ Received',
        value   : 6,
    },
    {
        title   : 'NJ Submitted',
        value   : 7,
    }, 
]
export const changeVisaStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'Application Lodged',
        value   : 3,
    },
    {
        title   : 'Info Requested',
        value   : 9,
    },
    {
        title   : 'Info Submitted',
        value   : 10,
    },
    // {
    //     title   : 'RFI Received',
    //     value   : 4,
    // },
    // {
    //     title   : 'RFI Response Submitted',
    //     value   : 5,
    // },
    // {
    //     title   : 'Natural Justice Received',
    //     value   : 6,
    // },
    // {
    //     title   : 'Natural Justice Response Submitted',
    //     value   : 7,
    // },
    // {
    //     title   : 'RFI / NJ Received',
    //     value   : 8,
    // },
    // {
    //     title   : 'RFI Sub / NJ Rec',
    //     value   : 9,
    // },
    // {
    //     title   : 'RFI Rec / NJ Sub',
    //     value   : 10,
    // },
    // {
    //     title   : 'RFI / NJ Submitted',
    //     value   : 11,
    // },
    {
        title   : 'Invalid Application',
        value   : 12,
    },
    {
        title   : 'Discontinued',
        value   : 13,
    },
    {
        title   : 'Application Approved',
        value   : 100,
    },
    {
        title  : 'Application Refused',
        value   : 101,
    },
    {
        title  : 'Application Withdrawn',
        value   : -1,
    },
    
]
export const noiccStatus  = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'NOICC Received',
        value   : 2,
    },
    {
        title   : 'NOICC Response Submitted',
        value   : 3,
    },
    {
        title   : 'RFI Received',
        value   : 4,
    },
    {
        title   : 'RFI Response Submitted',
        value   : 5,
    },
    {
        title   : 'Discontinued',
        value   : 13,
    },
    {
        title   : 'Visa Not Cancelled',
        value   : 100,
    },
    {
        title   : 'Visa Cancelled',
        value   : 101,
    },
]
export const eoiStatus  = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'EOI Submitted',
        value   : 3,
    },
    {
        title   : 'Discontinued',
        value   : 13,
    },
    {
        title   : 'Invitation Received',
        value   : 100,
    },
    {
        title   : 'EOI Expired',
        value   : 101,
    },
]
export const roiStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'ROI Submitted',
        value   : 3,
    },
    {
        title   : 'Discontinued',
        value   : 13,
    },
    {
        title   : 'Invitation Received',
        value   : 100,
    },
    {
        title   : 'ROI Expired',
        value   : 101,
    },
]
export const changeEduStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'Application Lodged',
        value   : 3,
    },
    {
        title   : 'Info Requested',
        value   : 4,
    },
    {
        title   : 'Info Submitted',
        value   : 5,
    }, 
    {
        title   : 'Full Offer Received',
        value   : 6,
    },
    {
        title   : 'Conditional Offer Received',
        value   : 102,
    },
    {
        title   : 'Discontinued',
        value   : 7,
    },
    {
        title   : 'GTE Assessment',
        value   : 8,
    },
    {
        title   : 'Refund Applied',
        value   : 104,
    },
    {
        title   : 'Refund Paid',
        value   : 105,
    },
    {
        title   : 'Offer Accepted',
        value   : 100,
    },
    {
        title   : 'Offer Accepted - COE Received',
        value   : 9,
    },
    {
        title   : 'Offer Not Accepted',
        value   : 103,
    },
    {
        title   : 'Application Refused',
        value   : 101,
    },
    {
        title   : 'Application Withdrawn',
        value   : 0,
    },
    {
        title   : 'Application Withdrawn',
        value   : -100,
    },
    {
        title   : 'Application Withdrawn',
        value   : -3,
    },
]
export const eduStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'Application Lodged',
        value   : 3,
    },
    {
        title   : 'Info Requested',
        value   : 4,
    },
    {
        title   : 'RFI Received',
        value   : 4,
    },
    {
        title   : 'RFI Response Submitted',
        value   : 5,
    },
    {
        title   : 'Full Offer Received',
        value   : 6,
    },
    {
        title   : 'Conditional Offer Received',
        value   : 102,
    },
    {
        title   : 'Discontinued',
        value   : 7,
    },
    {
        title   : 'GTE Assessment',
        value   : 8,
    },
    {
        title   : 'Refund Applied',
        value   : 104,
    },
    {
        title   : 'Refund Paid',
        value   : 105,
    },
    {
        title   : 'Offer Accepted',
        value   : 100,
    },
    {
        title   : 'Offer Accepted - COE Received',
        value   : 9,
    },
    {
        title   : 'Offer Not Accepted',
        value   : 103,
    },
    {
        title   : 'Application Refused',
        value   : 101,
    },
    {
        title   : 'Application Withdrawn',
        value   : 0,
    },
    {
        title   : 'Application Withdrawn',
        value   : -100,
    },
    {
        title   : 'Application Withdrawn',
        value   : -3,
    },
]
export const educationStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'Application Lodged',
        value   : 3,
    },
    {
        title   : 'RFI Received',
        value   : 4,
    },
    {
        title   : 'RFI Response Submitted',
        value   : 5,
    },
    {
        title   : 'Full Offer Received',
        value   : 6,
    },
    {
        title   : 'Discontinued',
        value   : 7,
    },
    {
        title   : 'RFI / NJ Received',
        value   : 8,
    },
    {
        title   : 'RFI Sub / NJ Rec',
        value   : 9,
    },
    {
        title   : 'RFI Rec / NJ Sub',
        value   : 10,
    },
    {
        title   : 'RFI / NJ Submitted',
        value   : 11,
    },
    {
        title   : 'Offer Accepted',
        value   : 100,
    },
    {
        title   : 'Application Refused',
        value   : 101,
    },
    {
        title   : 'Conditional Offer Received',
        value   : 102,
    },
    {
        title   : 'Offer Not Accepted',
        value   : 103,
    },
    {
        title   : 'Refund Applied',
        value   : 104,
    },
    {
        title   : 'Refund Paid',
        value   : 105,
    },
    {
        title   : 'Application Withdrawn',
        value   : 0,
    },
]
export const migrationStatus = [
    {
        title   : 'Prospect',
        value   : 1,
    },
    {
        title   : 'Application Received',
        value   : 2,
    },
    {
        title   : 'Application Lodged',
        value   : 3,
    },
    {
        title   : 'RFI Received',
        value   : 4,
    },
    {
        title   : 'RFI Response Submitted',
        value   : 5,
    },
    {
        title   : 'NJ Received',
        value   : 6,
    },
    {
        title   : 'NJ Response Submitted',
        value   : 7,
    },
    {
        title   : 'RFI / NJ Received',
        value   : 8,
    },
    {
        title   : 'RFI Sub / NJ Rec',
        value   : 9,
    },
    {
        title   : 'RFI Rec / NJ Sub',
        value   : 10,
    },
    {
        title   : 'RFI / NJ Submitted',
        value   : 11,
    },
    {
        title   : 'Invalid Application',
        value   : 12,
    },
    {
        title   : 'Discontinued',
        value   : 13,
    },
    {
        title   : 'NOICC Received',
        value   : 14,
    },
    {
        title   : 'NOICC Submitted',
        value   : 15,
    },
    {
        title   : 'Application Approved',
        value   : 100,
    },
    {
        title   : 'Application Refused',
        value   : 101,
    },
    {
        title   : 'Offer not Accepted',
        value   : 103,
    },
    {
        title   : 'Application Withdrawn',
        value   : 0,
    },
]
export const reportType = [
    {
        title   : 'Course',
        value   : 'course',
    },
    {
        title   : 'Visa',
        value   : 'visa',
    },
    {
        title   : 'Passport',
        value   : 'passport',
    },
    {
        title   : 'License',
        value   : 'license',
    },
    {
        title   : 'OSHC',
        value   : 'oshc',
    },
    {
        title   : 'Skill  Assessment',
        value   : 'skill',
    },
    {
        title   : 'Police Check',
        value   : 'police',
    },
    {
        title   : 'English Test',
        value   : 'ielts',
    },
    {
        title   : 'Health Refund',
        value   : 'health',
    },
    {
        title   : 'Tution Fee Refund',
        value   : 'fee',
    },
]
export const sortBy = [
    {
        title   : 'Expiry Date',
        value   : 'expiry',
    },
    {
        title   : 'User Creation Date',
        value   : 'create',
    },

]
export const courseSortBy = [
    {
        title   : 'Start Date',
        value   : 'startdate',
    },
    {
        title   : 'End Date',
        value   : 'enddate',
    },

]
export const paymentStatus = [
    {
        label   : 'Paid',
        value   : 'paid',
    },
    {
        label   : 'Pending',
        value   : 'pending',
    },

]
export const commissionSortBy = [
    {
        label   : 'Due Date',
        value   : 'duedate',
    },
    {
        label   : 'Over Due',
        value   : 'overdue',
    },

]
export const oshcSortBy = [
    {
        title   : 'Oshc Applied',
        value   : 'applied',
    },
    {
        title   : 'Apply oshc',
        value   : 'apply',
    },
]
export const feeSortby = [
    {
        title   : 'Fee-Refund Applied',
        value   : 'applied',
    },
    {
        title   : 'Apply Fee-Refund',
        value   : 'apply',
    },
]

export const allSortBy = [
    {
        title   : 'Expiry Date',
        value   : 'expiry',
    },
    {
        title   : 'Issue Date',
        value   : 'issue',
    },
    {
        title   : 'User Creation Date',
        value   : 'create',
    },

]
export const note_type = [
    {
        title   : 'Whatsapp',
        value   : 1,
    },
    {
        title   : 'Call',
        value   : 2,
    },
    {
        title   : 'Skype',
        value   : 3,
    },
    {
        title   : 'Email Sent',
        value   : 4,
    },
    {
        title   : 'Email Received',
        value   : 5,
    },
    {
        title   : 'In-Person',
        value   : 6,
    },
    {
        title   : 'Case Work',
        value   : 7,
    },
    {
        title   : 'Zoom Consultation',
        value   : 8,
    },

]
export const agent_type = [
    {
        title   : 'Aptitude Office',
        value   : 1,
    },
    {
        title   : 'External Referring Agent',
        value   : 2,
    },
    {
        title   : 'External Processing Agent',
        value   : 5,
    },
    {
        title   : 'Campaign',
        value   : 3,
    },
    {
        title   : 'Others',
        value   : 4,
    },
    // {
    //     title   : 'External Agent',
    //     value   : 6,
    // },
]
export const app_location = [
    {
        title   : 'onshore',
    },
    {
        title   : 'offshore',
    },
]
export const noteFor = [
    {
        title   : 'clients',
    },
    {
        title   : 'edu',
    },
    {
        title   : 'mig',
    },
    {
        title   : 'providers',
    },
]
export const form_type = [
    {
        title   : 'Immigration',
    },
    {
        title   : 'Registration',
    },
]

export const preferred_country = [
    {
        title   : 'Australia',
    },
    {
        title   : 'UK',
    },
    {
        title   : 'USA',
    },
]
export const external_agent_type = [
    {
        title   : 'Referring',
        value  : 'referring',
    },
    {
        title   : 'Processing',
        value   : 'processing',
    },
]
export const user_role = [
    {
        title   : 'Admin',
        value  : '0',
    },
    {
        title   : 'User',
        value   : '1',
    },
    {
        title   : 'Super Admin',
        value   : '2',
    },
]
export default {
    changeVisaStatus,
    external_agent_type,
    preferred_country,
    commissionSortBy,
    paymentStatus,
    oshcSortBy, 
    gstbycountry, 
    migrationType, 
    visaStatus, 
    noiccStatus, 
    eoiStatus, 
    roiStatus, 
    eduStatus, 
    educationStatus, 
    migrationStatus, 
    reportType, 
    sortBy, 
    courseSortBy, 
    allSortBy , 
    app_location, 
    noteFor, 
    user_role,
    rfiStatus,
    njStatus,
    changeEduStatus,}