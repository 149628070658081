
import React, { Component } from 'react';
import { Modal, Button } from "react-bootstrap";
import { countries_code, education_category, healthProviders } from '../../utilis/constants';
import { eduStatus, note_type } from '../../utilis/appStatus';
import Multiselect from 'multiselect-react-dropdown';

class Modals extends Component {
    constructor(props) {
        super(props);
        this.state = {
          oldPassError: '', newPassError:'', confirmNewPassError:'',
          show: false,
          value1:'',
          value2:'',
          value3:'',
          value4:'',
          value5:'',
          value6:'',
          value7:'',
          name:'',
          email:'',
          mobile:'',
          country:'',
          type: '',
          confirmNewPass: '',
          oldPass: '',
          newPass: '',
          checkbox: false,
          checkbox2: false,
          courses:[],
          selectedFile:'',
          selectedFile1:'',
          selectedFile2:'',
          healthClaim: '',
          addMore:'false',
          ceoFiles:[{file:'', error_file:''}],
          paymentFiles:[{file:'', error_file:''}],
          oshcRecipt:'',
          oshcCertificate:'',
          provider:'',
          groups:[],
          tags:[],
          refundError:false,
          approvalFiles:[{file:'', error_file:''}],
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleGroupChange = this.handleGroupChange.bind(this);
        this.handleOptionChange = this.handleOptionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleProceed = this.handleProceed.bind(this);
        this.handleTeamSubmit = this.handleTeamSubmit.bind(this);
        this.handlePassSubmit = this.handlePassSubmit.bind(this);
        this.handleAssignUserSubmit = this.handleAssignUserSubmit.bind(this);
        this.handleApprovalSubmit = this.handleApprovalSubmit.bind(this);
        this.handleEduApprovalSubmit = this.handleEduApprovalSubmit.bind(this);
    }

    handleModal(){
        this.setState({checkbox: false, checkbox2:false,healthClaim: '', 
        bonusList:[{startdate: '', enddate:'', bonus:'',startdate_error: '', enddate_error:'', bonus_error:'' }]});
         this.props.parentCallback(this.props.show, false); 
    } 

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }
    handleGroupChange(event) {
        const selectedGroupId = event.target.value;
        const { data, data1 } = this.props;
      
        const record = data.find((element) => {
          return element.id == selectedGroupId;
        });
      
        if (record) {
          const usingSplit = record.users.split(',');
          const options = usingSplit.map((intake) => {
            const name = data1.find((element) => {
              if (element.value == intake) {
                const newName = { label: element.label, value: element.value };
                console.log(newName);
                return newName; // Add a return statement here
              }
            });
            
            // Return null or undefined if the condition is not met
            return name;
          });
      
          // Remove the commented-out code and update the state
          this.setState({
            tags: options.filter(Boolean), // Remove null or undefined values
            [event.target.name]: event.target.value,
          });
        } else {
          this.setState({ tags: [], [event.target.name]: event.target.value });
        }
      }
      
      
    handleOptionChange(e) {
        this.setState({
            [e.target.name]: e.target.options[e.target.selectedIndex].text
        });
      }
    handleApprovalSubmit(){
        // this.setState({addMore: false, approvalFiles:[]});
        const list = [...this.state.approvalFiles];
        if(!list[0]['file']){
            list[0]['error_file'] = 'Please upload approval file';
            this.setState({approvalFiles: list});
        } else{
            list[0]['error_file'] = '';
            this.setState({approvalFiles: list});
            this.props.parentCallback(
                this.props.show,
                'edit',
                this.state.name,
                this.state.approvalFiles
            );
        }
    }
    handleEduApprovalSubmit(){
        // this.setState({addMore: false, approvalFiles:[]});
        const list = [...this.state.approvalFiles];
        if(!list[0]['file']){
            list[0]['error_file'] = 'Please upload approval file';
            this.setState({approvalFiles: list});
        } else{
            list[0]['error_file'] = '';
            this.props.parentCallback(
                this.props.show,
                'edit',
                this.state.name,
                this.state.email,
                this.state.mobile,
                this.state.country,
                this.state.courses[0] ? this.state.courses : this.props.courses,
                this.state.approvalFiles,
                this.state.paymentFiles,
                this.state.ceoFiles,
                this.state.healthClaim,
                this.state.oshcRecipt,
                this.state.oshcCertificate,
                this.state.provider,
                );
                this.setState({approvalFiles: list,addMore: false, ceoFiles:[], checkbox: false, checkbox2:false, healthClaim: ''});
        }
    }
    handleSubmit() {
        if((this.props.appid === 9 || this.props.appid === '9') && this.props.from === 'mig'){
            this.props.parentCallback(
                this.props.show,
                'edit',
                this.state.name,
                this.state.checkbox,
                this.state.email,
                this.state.checkbox2,
                this.state.mobile,
            )
        } else if(this.props.appid === 10 || this.props.appid === '10'){
            this.props.parentCallback(this.props.show, 'edit', this.state.name, this.state.checkbox, this.state.checkbox2)
        } else{
            this.props.parentCallback(
                this.props.show,
                'edit',
                this.state.name,
                this.state.email,
                this.state.mobile,
                this.state.country,
                this.state.courses[0] ? this.state.courses : this.props.courses,
                this.state.selectedFile[0] ? this.state.selectedFile : this.state.approvalFiles,
                this.state.paymentFiles,
                this.state.ceoFiles[0] ? this.state.ceoFiles: this.state.paymentFiles,
                this.state.healthClaim,
                this.state.oshcRecipt,
                this.state.oshcCertificate,
                this.state.provider,
            );
        }
        this.setState({addMore: false, ceoFiles:[], paymentFiles:[], checkbox: false, checkbox2:false, healthClaim: ''});
    }

    handlePassSubmit() {
        this.setState({oldPassError: null, newPassError: null, confirmNewPassError: null})
        if(!this.state.oldPass){
            this.setState({oldPassError : 'Please enter old password'})
            return false;
        }
        else if(!this.state.newPass){
            this.setState({newPassError : 'Please enter new password'}) 
            return false;
        }
        else if(this.state.confirmNewPass !== this.state.newPass){
            this.setState({confirmNewPassError: 'Password do not match'}) 
            return false;
        }
        else{
        this.props.parentCallback(
            this.props.show,
            'pass',
            this.state.oldPass,
            this.state.newPass,
            this.state.confirmNewPass,
            null); }
    }
    handleAssignUserSubmit(){
        this.setState({tags:[]})
        this.props.parentCallback(
            this.props.show,
            'edit',
            this.state.value1,
            this.state.groups
        )
    }
    handleTeamSubmit() {
        this.setState({name: '', value1: '', value2:'', value3:'', value4:'', value5:'', value6:'', value7:''})
        this.props.parentCallback(
            this.props.show,
            this.props.type,
            this.state.name,
            this.state.value1,
            this.state.value2, 
            this.state.value3, 
            this.state.value4,
            this.state.value5,
            this.state.value6,
            this.state.value7,
            this.props.data,
            )
    }
    handleProceed() {
        this.setState({value1:'', value2:''});
        this.props.parentCallback(
            this.props.show,
            'edit',
            this.state.value1,
            this.state.value2,
        );
    }
      // handle input change
    handleInputChange = (e, index) => {
        this.setState({courses: this.props.courses});
        const { name, value } = e.target;
        const list = [...this.state.courses];
        list[index][name] = value;
        this.setState({courses: list});
    };
    fileSelectedHandler = event => {
        this.setState({selectedFile:event.target.files[0]});
    }
    fileSelectedHandlerOshc = event => {
        this.setState({oshcRecipt:event.target.files[0]});
    }
    fileSelectedHandlerOshcCertificate= event => {
        this.setState({oshcCertificate:event.target.files[0]});
    }
    fileSelectedHandler1 = event => {
        console.log('dd');
        this.setState({selectedFile1:event.target.files[0]});
    }
    fileSelectedHandler2(e, index){
        console.log('dd');
        e.preventDefault();
        const list = [...this.state.ceoFiles];
        list[index]['file'] = e.target.files[0];
        this.setState({ceoFiles:list});
    }
    fileSelectedHandler3(e, index){
        console.log(index);
        e.preventDefault();
        const list = [...this.state.paymentFiles];
        list[index]['file'] = e.target.files[0];
        this.setState({paymentFiles: list});
    }
    addMore = (e) => {
        e.preventDefault();
        this.setState({ceoFiles: [...this.state.ceoFiles, {  file: "",}]});
    }
    addMorePayment = (e) => {
        e.preventDefault();
        this.setState({paymentFiles: [...this.state.paymentFiles, {  file: "",}]});
    }
      // handle click event of the Remove button
    handleRemoveClick = (index, e) => {
        console.log(index);
        e.preventDefault();
        const list = [...this.state.ceoFiles];
        list.splice(index, 1);
        this.setState({ceoFiles: list});
    };
        // handle click event of the Remove button
    handleRemoveClickPayment = (index, e) => {
        console.log(index);
        e.preventDefault();
        const list = [...this.state.paymentFiles];
        list.splice(index, 1);
        this.setState({paymentFiles: list});
    };

    onUsersSelect = (selectedList) => {
        this.setState({groups: selectedList});
    }
    onUsersRemove = (selectedList) => {
        this.setState({groups: selectedList});
    }

    handleAddClick(e, index) {
        e.preventDefault();
        console.log('index', index);
        const list = [...this.state.approvalFiles];
        if(!list[index]['file']){
            list[index]['error_file'] = 'Please upload file first!';
            this.setState({approvalFiles: list});
        } else{
            list[index]['error_file'] = '';
            this.setState({approvalFiles: [...this.state.approvalFiles, {file: "", error_file:""}]});
        } 
    }

    handleApprovalRemoveClick(index, e){
        console.log(index)
        e.preventDefault();
        const list = [...this.state.approvalFiles];
        list.splice(index, 1);
        this.setState({approvalFiles: list});
    }
    handleApprovalInputChange(e, index){
        e.preventDefault();
        const list = [...this.state.approvalFiles];
        list[index]['file'] = e.target.files[0];
        this.setState({approvalFiles: list});
    }
    checkRefund(value){
        this.setState({refundError: false})
        if(value > this.props.paid){
            this.setState({refundError: true})
        } 
        this.setState({name:value})

    }
    
  render(){ 
      return(
          <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered  show={this.props.show} onHide={()=>this.handleModal()}>  
                <Modal.Header closeButton={this.props.endButton == true ? "" : true}><div className="text-center col-11 font-f-sans white">{this.props.title}</div></Modal.Header>  
                <Modal.Body className="ml-2 mr-2">
                {this.props.type == 'edit' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-6" >
                <label className="profile-label">Name:</label>
                <input name="name" onChange={this.handleChange}  className="card-text form-control acc-form col-11" defaultValue={this.props.data['name']} />
                </div>
                <div className="form-group col-6" >
                <label className="profile-label">Email:</label>
                <input name="email" onChange={this.handleChange}  className="card-text form-control acc-form col-11" defaultValue={this.props.data['email']} />
                </div>
                <div className="form-group col-6" >
                <label className="profile-label ">Mobile:</label>
                <input name="mobile" onChange={this.handleChange}  className="card-text form-control acc-form col-11" defaultValue={this.props.data['mobile']} />
                </div>
                <div className="form-group col-6" >
                <label className="profile-label ">Country:</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg w-lg-90" defaultValue={this.props.data['country']} aria-label="form-select-lg example" name="country" >
                    <option value="Pakistan">Pakistan</option>
                    <option value="Australia">Australia</option>
                    <option value="Other">Other</option>
                </select>
                </div>
                </div>
                </form>
                :''}
                {this.props.type === 'pass' ? 
                <form action='' onSubmit={this.handlePassSubmit}>
                <div className="row">
                <div className="form-group col-12" >
                <label className="profile-label">Old Password:</label>
                <input type="password" name="oldPass" onChange={this.handleChange}  className="card-text form-control acc-form col-11" />
                {this.state.oldPassError && <><small className="error">{this.state.oldPassError}</small></>}
                </div>
                <div className="form-group col-12" >
                <label className="profile-label">New Password:</label>
                <input type="password" name="newPass" onChange={this.handleChange}  className="card-text form-control acc-form col-11" />
                {this.state.newPassError && <><small className="error">{this.state.newPassError}</small></>}
                </div>
                <div className="form-group col-12" >
                <label className="profile-label">Confirm New Password:</label>
                <input type="password" name="confirmNewPass" onChange={this.handleChange}  className="card-text form-control acc-form col-11" />
                {this.state.confirmNewPassError && <><small className="error">{this.state.confirmNewPassError}</small></>}
                </div>
                </div>
                </form>
                : this.props.type === 'category' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-12" >
                {this.props.textarea && !this.props.date  && this.props.noteType != false ? 
                <>
                <label className="profile-label">Medium</label>
                <select defaultValue={this.state.email} onChange={this.handleChange} name='email' className="form-select acc-form w-100 mb-3">
                <option value=''>Please select</option>
                {note_type.map(head => 
                <option key={head.value} value={head.value}>{head.title}</option>)}
                </select>
                </>:''}

                {this.props.tags && this.props.tags[0] && 
                <>
                <div className='col-lg-6 mb-lg-3 pl-0'>
                <label className="acc-label2 mb-0">Description Tags</label>
                <select className="form-select acc-form form-select-lg w-lg-90" aria-label="form-select-lg example"
                onChange={(e) => this.setState({name:e.target.value})}>
                <option  value=''>Please Select</option>
                {[].concat(this.props.tags).sort((a, b) => a.name > b.name ? 1 : -1).map(value =>
                <option key={value.id} className="light" value={value.description}>{value.name}</option> )}
                </select>
                </div>
                </>}

                <label className="profile-label">{this.props.data}</label>
                {this.props.textarea && !this.props.date ? 
                <>
                <textarea className="textarea form-control app-form" placeholder="Please Enter" 
                name="name" defaultValue={this.state.name} onChange={this.handleChange} rows="4" cols="60"></textarea>
            
                {this.props.healthRefund == true ?
                <>
                <div className='row  mt-2'>
                <label className='profile-label col-lg-7' for="myfile">Have you purchased OSHC?</label>
                <div className="form-check col-2">
                <input type="radio"
                className="form-check-input" name="healthClaim" id="radio1" value="1" 
                onClick={() => this.setState({healthClaim: 1})} checked={this.state.healthClaim == 1 ? true : false} />Yes
                <label className="form-check-label" for="radio1"></label>
                </div>
                <div className="form-check ml-2 col-2">
                <input type="radio" 
                className="form-check-input" name="healthClaim" id="radio2" value="0" 
                onClick={() => this.setState({healthClaim: 0})} checked={this.state.healthClaim  == 0 ? true : false} />No
                <label className="form-check-label" for="radio2"></label>
                </div>
                </div>
                {this.state.healthClaim === 1 ?
                <div className='p-2 border'>
                <label className='profile-label mt-2' for="myfile">Start Date of OSHC</label>
                <input type='date' className="date-input w-lg-90 ml-1" placeholder="Please Enter" 
                name="email" onChange={this.handleChange} />
                <label className='profile-label mt-2' for="myfile">End Date of OSHC</label>
                <input type='date' className="date-input w-lg-90 ml-1" placeholder="Please Enter" 
                name="mobile"  onChange={this.handleChange} />
                <label className='profile-label mt-2' for="myfile">Amount</label>
                <input type='text' className="card-text form-control acc-form col-7" placeholder="Please Enter" 
                name="country"  onChange={this.handleChange} />
                <label className='profile-label mt-2' for="myfile">Provider: </label>
                <select className="form-select app-form col-7" name="provider" 
                onChange={this.handleChange} >
                <option value=''>Please Select </option>
                {healthProviders.map((v, index) =>
                <option key={index} className="light">{v.title}</option> )}
                </select>
                <label className='profile-label mt-2' for="myfile">Oshc Reciept : </label>
                <input type="file" onChange={this.fileSelectedHandlerOshc} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                <label className='profile-label mt-2' for="myfile">Oshc Certificate: </label>
                <input type="file" onChange={this.fileSelectedHandlerOshcCertificate} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                </div>:''}

                </>:''}

                {/* upload docs */}
                {(this.props.uploadDoc1 == true && this.props.multipleFiles == false && (this.props.appid != 9 || this.props.appid != "9")) ? 
                <>
                <label className='profile-label mt-2' for="myfile">{this.props.file1name}</label>
                <input type="file" onChange={this.fileSelectedHandler} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                </>
                :
                (this.props.uploadDoc1 == true && this.props.multipleFiles == true && (this.props.appid != 9 || this.props.appid != "9")) ?
                <>
                {/* upload docs */}
                {this.state.approvalFiles.map((x, i) => {
                return(
                <div key={x} className="row">
                <div className="form-group col-12" >
                <label className='profile-label mt-2' for="myfile">{this.props.multipleFilesName} </label>
                <input type="file" onChange={(e) => this.handleApprovalInputChange(e, i)} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                {x.error_file && <><small className="error col-6">{x.error_file}</small></>}
                </div>
                <div className="d-flex" >
                {this.state.approvalFiles.length !== 1 && <button
                className="btn btn-danger mr-2" onClick={(e) => this.handleApprovalRemoveClick(i, e)}>Remove</button>}
                {this.state.approvalFiles.length - 1 === i &&
                <button className="btn btn-primary" onClick={(e) => this.handleAddClick(e, i)}>Add More</button>}
                </div>
                </div>)})}
                </>
                :''}
                    {/* upload docs */}
                {this.props.selectedAppId == 9 &&
                <>
                {this.state.ceoFiles.map((x, i) => {
                return(
                <div key={i} className="row">
                <div className="form-group col-12" >
                <label className='profile-label mt-2'>Upload COE Acceptance</label>
                <input type="file" onChange={(e) => this.fileSelectedHandler2(e, i)} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                </div>
                {this.state.ceoFiles.length !== 1 && <button
                className="btn btn-danger mr-2" onClick={(e) => this.handleRemoveClick(i, e)}>Remove</button>}
                </div>)})}
                </>}

                {this.props.file1name == 'Upload COE Acceptance' && this.props.selectedAppId == 9? 
                <button className='btn btn-primary my-2' onClick={(e) => this.addMore(e)}>Add More</button> : '' }
            

                {this.props.uploadDoc2 == true ? 
                <>
                <br/>
                {/* <label className='profile-label mt-2' for="myfile">Upload payment Receipt:</label>
                <input type="file" onChange={this.fileSelectedHandler1} className="form-control acc-form w-lg-90" placeholder="Please Enter"/> */}
                {this.state.paymentFiles.map((x, i) => {
                return(
                <div key={x} className="row">
                <div className="form-group col-12" >
                <label className='profile-label mt-2' for="myfile">Upload payment Receipt:</label>
                <input type="file" onChange={(e) => this.fileSelectedHandler3(e, i)} className="form-control acc-form w-lg-90" placeholder="Please Enter"/>
                </div>
                <div className='d-flex'>
                <button className='btn btn-primary mr-2' onClick={(e) => this.addMorePayment(e)}>Add More</button>
                {this.state.paymentFiles.length !== 1 && <button
                className="btn btn-danger" onClick={(e) => this.handleRemoveClickPayment(i, e)}>Remove</button>}
                </div>
                </div>)})}
                </>
                :''}
                {this.props.courses && this.props.courses[0] &&
                <>
                {this.props.courses.map((v, index) => { 
                return(
                    <div key={v} className="row mb-2 mt-2 border p-2">
                    <label className="text-center bold font-f-monst-sans bg-white-smoke size-15 p-1 mb-3 pt-1">Course </label>
                    <div className="col-lg-6">
                    <label className="acc-label2 mb-1 size-15">Course:</label>
                    <input type="text" className="form-control acc-form w-lg-90" defaultValue={v.name} 
                    placeholder="Please enter" onChange={(e) => this.handleInputChange(e, index)} disabled/>
                    </div>
                    
                    <div className='col-lg-6'>
                    <label className="acc-label2 mb-1 size-15">Total Tuition Fee:</label>
                    <input type="text" className="form-control acc-form w-lg-90" defaultValue={v.offered_fee} 
                    placeholder="Please enter" name="offered_fee" onChange={(e) => this.handleInputChange(e, index)}/>
                    </div>

                    <div className="col-lg-6">
                    <label className="acc-label2 mb-1 size-15">Start Date:</label>
                    <input type="date" className="form-control acc-form w-lg-90" defaultValue={v.startdate} 
                    placeholder="Please enter" name="startdate" onChange={(e) => this.handleInputChange(e, index)} />
                    </div>

                    <div className="col-lg-6">
                    <label className="acc-label2 mb-1 size-15">End Date:</label>
                    <input type="date" className="form-control acc-form w-lg-90" defaultValue={v.enddate} 
                    placeholder="Please enter" name="enddate" onChange={e => this.handleInputChange(e, index)}/>
                    </div>

                </div>)} )}
                </>}
                 </>
                : this.props.textarea && this.props.from === 'mig' ? 
                <>
                {/* Info Requested */}
                <textarea className="textarea form-control app-form" placeholder="Please Enter" 
                name="name" onChange={this.handleChange} rows="4" cols="60"></textarea>
                
                {(this.props.appid ===94 || this.props.appid === '9') &&
                <>
                <div className='form-group col-12 mt-2'>
                <input className="form-check-input" onChange={(e) => this.setState({checkbox: e.target.checked})} type="checkbox"  />
                <label className="form-check-label profile-label">RFI Received</label>
                {this.state.checkbox === true &&
                <>
                <br/>
                <label className='form-check-label profile-label '>Rfi Due Date</label>
                <input type="date" onChange={this.handleChange} name='email' className='date-input col-12' />
                </>}
                </div>
                <div className='form-group col-12 mt-2'>
                <input className="form-check-input" onChange={(e) => this.setState({checkbox2: e.target.checked})} type="checkbox"  />
                <label className="form-check-label profile-label">NJ Received</label>
                {this.state.checkbox2 === true &&
                <>
                <br/>
                <label className='form-check-label profile-label '>NJ Due Date</label>
                <input type="date" onChange={this.handleChange} name='mobile' className='date-input col-12' />
                </>}
                </div>
                </>}
                {(this.props.appid === 10 || this.props.appid === '10') &&
                <>
                <div className='form-group col-12 mt-2'>
                <input className="form-check-input" onChange={(e) => this.setState({checkbox: e.target.checked})} type="checkbox"  />
                <label className="form-check-label profile-label ps-1">RFI Submitted</label>
                </div>
                <div className='form-group col-12 mt-2'>
                <input className="form-check-input" onChange={(e) => this.setState({checkbox2: e.target.checked})} type="checkbox"  />
                <label className="form-check-label profile-label ps-1">NJ Submitted</label>
                </div>
                </>}
                {/* when app type no mig then show this */}
                {(this.props.date == true && this.props.from != 'mig') ? <>
                <label className='mt-2'>{this.props.dateText}</label>
                <input type="date" onChange={this.handleChange} name='email' className='date-input col-12' /></>:''}
                {this.props.date2 == true ?
                <> <label className='mt-2'>{this.props.dateText2}</label>
                <input type="date" onChange={this.handleChange} name='mobile' className='date-input col-12' /></>
                :''}
                </>
                :
                <>
                <input type="text" placeholder="Please enter" defaultValue={this.props.defaultValue ? this.props.defaultValue : ''} 
                name="name" onChange={this.handleChange} 
                className="card-text form-control acc-form col-11" />
                {this.props.smallLabel &&<small className='text-muted pl-lg-1 size-13'>{this.props.smallLabel}</small>}
                </>}

                {this.props.reschedule=== true &&
                <>
                <div className="form-check pt-4">
                <input className="form-check-input" defaultChecked={this.state.checkbox} onChange={(e) => this.setState({checkbox: e.target.checked})} type="checkbox" />
                <label className="form-check-label">Reschedule the Follow up</label>
                {this.state.checkbox === true ? 
                <input className="date-input col-12 mt-3" defaultValue={this.state.mobile} onChange={this.handleChange} name="mobile" type="date" />:''}
                </div>
                </>
                }

                {this.props.reassign === true &&
                <>
                <div className="form-check pt-4">
                <input className="form-check-input" defaultChecked={this.state.checkbox2} onChange={(e) => this.setState({checkbox2: e.target.checked})} type="checkbox" />
                <label className="form-check-label">ReAssign the Follow up</label>
                {this.state.checkbox2 === true &&
                <select className="form-select app-form" name="country" 
                onChange={this.handleChange} >
                <option value='0'>Assign To </option>
                {[].concat(this.props.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map((v, index) =>
                <option selected={v.id == this.props.assignedTo && true}
                key={index} className="light" value={v.id}>{v.name}</option> )}
                </select>}
                </div>
                </>
                }

                {this.props.countryDropdown == true &&
                <>
                <br/>
                <label className="profile-label mt-lg-2">Country</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg col-lg-11" name="email" >
                <option value="">Please Select</option>
                {countries_code.map(head => 
                <option
                key={head.country}
                selected={head.country === this.props.defaultCountryValue} 
                value={head.value}>{head.value}</option>
                )}
                </select>
                </>
                }

                {this.props.categoryDropdown == true &&
                <>
                <br/>
                <label className="profile-label">Category</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg col-lg-11" name="email" >
                <option value="">Please Select</option>
                {education_category.map(head => 
                <option
                key={head.title}
                selected={head.title === this.props.defaultTitleValue} 
                value={head.title}>{head.title}</option>
                )}
                </select>
                </>
                }
                </div>
                {this.props.dropdown == true &&
                <>
                <label className="profile-label">Note For</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg" name="email" >
                <option value="">Please Select</option>
                <option value="0">All</option>
                <option value="1">Super Admin</option>
                <option value="2">Admin</option>
                <option value="3">User</option>
                <option value="4">Accountant</option>
                </select>
                </>}
                </div>

                {this.props.clientNote &&
                <>
                <div className='col-12 my-1 size-15'>
                <input className="form-check-input" name="mobile" type="checkbox" 
                onChange={(e) =>  this.setState(prevState => ({mobile: !prevState.mobile // Toggle the value
                    }))} checked={this.state.mobile ? this.state.mobile : false}
                />
                <label className="form-check-label profile-label">Show this note to client portal!</label>
                </div>
                </>}

                </form>
                : this.props.type === 'refund' ?
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row justify-content-center">
                <div className="col-lg-4">
                <div className="d-flex p-1 ">
                <h5 className="bold">Paid:</h5>
                <h5 className={`acc-label pl-2 ${this.props.paid > 0.00 ? ' active bold ': ''}`}>{this.props.paid}</h5>
                </div>
                </div>

                <div className="col-lg-4">
                <div className="d-flex p-1 ">
                <h5 className="bold">Remaining:</h5>
                <h5 className={`acc-label pl-2 ${this.props.remaining > 0.00 ? 'bold text-danger' : ''}`}>{this.props.remaining}</h5>
                </div>
                </div>

                <div className='col-lg-12 mt-3'>
                {this.props.paid > 0.00 ?
                <div>
                <input type='number' placeholder='Enter Refund Amount' onChange={(e) => this.checkRefund(e.target.value)}
                className='form-control acc-form col-12' name="name" />
                {this.state.refundError &&
                <small className='error'>Amount should not be more than paid amount</small>}
                </div>
                :
                <h5 className="error bold">No amount is paid. You cannot refund amount!</h5>}
                </div>
                </div>
                </form>
                : this.props.type === 'oshc' ?
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-12" >
                <label className="profile-label">Start date of Oshc</label>
                <input type='date' defaultValue={this.props.data && this.props.data['issuedate']} className="date-input w-lg-90 ml-1" placeholder="Please Enter" 
                name="name" onChange={this.handleChange} disabled={this.props.onlyView == 1 ? true : false} />
                <label className="profile-label mt-2">End date of Oshc</label>
                <input type='date' defaultValue={this.props.data && this.props.data['expirydate']} className="date-input w-lg-90 ml-1" placeholder="Please Enter" 
                name="email" onChange={this.handleChange} disabled={this.props.onlyView == 1 ? true : false} />
                <label className='profile-label mt-2' for="myfile">Amount</label>
                <input type='text' defaultValue={this.props.data && this.props.data['amount']} className="card-text form-control acc-form col-7" placeholder="Please Enter" 
                name="mobile"  onChange={this.handleChange} disabled={this.props.data && this.props.onlyView == 1 ? true : false} />
                <label className='profile-label mt-2' for="myfile">Provider: </label>
                <select disabled={this.props.data && this.props.onlyView == 1 ? true : false} className="form-select app-form col-7" name="country" 
                onChange={this.handleChange} >
                <option value=''>Please Select </option>
                {healthProviders.map((v, index) =>
                <option selected={this.props.data && v.title == this.props.data['providername'] ? true : false } key={index} className="light">{v.title}</option> )}
                </select>
                </div>
                </div>
                </form> 
                : this.props.type === 'attendance' ?
                <form action='' >
                <div className="row">

                <div className="form-group col-12">
                <label className="profile-label">Department</label>
                <select className="form-select app-form" name="name" onChange={this.handleChange} >
                <option value=''>Select Department </option>
                {this.props.department.map((v, index) =>
                <option key={index++} className="light" value={v.id}>{v.name}</option> )}
                </select>
                </div>

                <div className="form-group col-12">
                <label className="profile-label">Employee</label>
                <select className="form-select app-form" name="email" onChange={this.handleChange} >
                <option value=''>Select Employee </option>
                {[].concat(this.props.users).sort((a, b) => a.name > b.name ? 1 : -1).map((v, index) =>
                <option key={index++} className="light" value={v.id}>{v.name}</option> )}
                </select>
                </div>

                </div>
                </form>
                : this.props.type === 'expo' ? 
                <form action='' >
                <div className="row">
                <div className="form-group col-12">
                <label className="profile-label">{this.props.data}</label>
                <textarea className="textarea form-control app-form" placeholder="Please Enter" 
                name="name" defaultValue={this.state.name} onChange={this.handleChange} rows="4" cols="60"></textarea>
                
                {this.props.reassign == true &&
                <div className="form-check pt-4">
                <input className="form-check-input" defaultChecked={this.state.checkbox2} onChange={(e) => this.setState({checkbox2: e.target.checked, email: ''})} type="checkbox" />
                <label className="form-check-label">ReAssign the Follow up</label>
                {this.state.checkbox2 === true &&
                <select className="form-select app-form" name="email" 
                onChange={this.handleChange} >
                <option value=''>ReAssign To </option>
                {[].concat(this.props.allUsers).sort((a, b) => a.name > b.name ? 1 : -1).map((v, index) =>
                <option key={index++} className="light" value={v.id}>{v.name}</option> )}
                </select>}
                </div>}
                {this.props.endButton == true &&
                <div>
                <h3 style={{color:'#0000bd'}}>{this.props.timer}</h3>
                </div>}
  
                </div>
                </div>
                </form>
                : this.props.type === 'priority' ? 
                <form action='' >
                <div className="row">
                <div className="form-group col-12">

                <div className="col-12 mt-2">
                <label className="profile-label">Select Date:  </label>
                <input type='date' className="date-input col-9 ml-1" placeholder="Please Enter" 
                name="name" defaultValue={this.props.data['priority_date']} onChange={this.handleChange} />
                </div>
                
                {/* <div className="col-12 d-flex mt-3">
                <label className="profile-label">Select Type: </label>
                <select onChange={this.handleChange} defaultValue={this.props.data['priority_type']} name='email' data-live-search="true" className="form-select acc-form size-12 col-9 ml-1">
                <option>Please Select</option>
                <option value={1} selected={this.props.data['priority_type'] == 1 ? true : false}>High</option>
                <option value={0} selected={this.props.data['priority_type'] == 0 ? true : false}>Normal</option>
                </select>
                </div> */}
                

                <div className="col-12 d-flex mt-3">
                <label className="profile-label">Desired Status: </label>
                <select onChange={this.handleChange} name='mobile' data-live-search="true" 
                className="form-select acc-form size-12 col-9 ml-1" >
                <option value={0}>Please Select </option>
                {this.props.status.map(value => 
                <option disabled={this.props.selectedStatus == value.value ? true : false} selected={this.props.data['priority_status'] == value.title ? true : false}
                 key={value.title} value={value.title}>{value.title}</option>)}
                </select>
                </div>
                
           
                </div>
                </div>
                </form>
                : this.props.type === 'addTeam' || this.props.type === 'editTeam' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-11" >
                <label className="profile-label">Team Name</label>
                <input type="text" placeholder="Please enter" defaultValue={this.props.type === 'editTeam' ? this.props.data['name'] : '' }  name="name" onChange={this.handleChange}  className="card-text form-control acc-form col-11" />
                </div>
                <div className="form-group row col-11" >
                <label className="font-f-monst-sans bold pt-2">Notification Access</label>
                <div className="col-4">
                <div className="form-check">
                <input className="form-check-input" name="value1" type="checkbox" onChange={(e) => this.setState({value1: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['general']) : '' }  />
                <label className="form-check-label">General</label>
                </div>
                <div className="form-check">
                <input className="form-check-input" name="value2" type="checkbox" onChange={(e) => this.setState({value2: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['mig']) : '' } />
                <label className="form-check-label">Migration</label>
                </div>
                <div className="form-check">
                <input className="form-check-input" name="value3" type="checkbox" onChange={(e) => this.setState({value3: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['edu']) : '' } />
                <label className="form-check-label">Education</label>
                </div>
                </div>
                <div className="col-4">
                <div className="form-check">
                <input className="form-check-input" name="value4" type="checkbox" onChange={(e) => this.setState({value4: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['marketing']) : '' }/>
                <label className="form-check-label">Marketing</label>
                </div>
                <div className="form-check">
                <input className="form-check-input" name="value5" type="checkbox" onChange={(e) => this.setState({value5: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['accounts']) : '' }  />
                <label className="form-check-label">Accounts</label>
                </div>
                </div>
                <div className="col-4">
                <div className="form-check">
                <input className="form-check-input" name="value6" type="checkbox" onChange={(e) => this.setState({value6: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['providers']) : '' } />
                <label className="form-check-label">Providers</label>
                </div>
                <div className="form-check">
                <input className="form-check-input" name="value7" type="checkbox" onChange={(e) => this.setState({value7: e.target.checked})} defaultChecked={this.props.type === 'editTeam' ? (this.props.data['clients']) : '' } />
                <label className="form-check-label">Clients</label>
                </div>
                </div>
        
                </div>
                </div>
                </form>
                : this.props.type === 'assignM' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-11" >
                <label className="profile-label">Select Teams:</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg" name="value1" >
                <option value="">Please Select</option>
                {[].concat(this.props.data).sort((a, b) => a.name > b.name ? 1 : -1).map(head => (
                    <option key={head.id} value={head.id}>{head.name}</option>
                ))}
                </select>
                </div>
                {this.state.value1 &&
                <div className="form-group col-11" >
                <label className="profile-label">Select Member:</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg" name="value2" >
                <option value="">Please Select</option>
                {[].concat(this.props.data1).sort((a, b) => a.name > b.name ? 1 : -1).map(head => (
                <option key={head.id} value={head.id}>{head.name}</option>
                ))}
                </select>
                </div>
                }
                </div>
                </form>
                : this.props.type === 'assignUserToGroup' ? 
                <form action='' onSubmit={this.handleSubmit}>
                <div className="row">
                <div className="form-group col-11" >
                <label className="profile-label">Select Group:</label>
                <select onChange={this.handleGroupChange} className="form-select acc-form form-select-lg" name="value1" >
                <option value="">Please Select</option>
                {[].concat(this.props.data).sort((a, b) => a.name > b.name ? 1 : -1).map(head => (
                    <option key={head.id} value={head.id}>{head.name}</option>
                ))}
                </select>
                </div>
                {this.state.value1 &&
                 <Multiselect
                options={this.props.data1}
                className="form-select app-form multi col-11"
                placeholder={'Group'}
                displayValue="label"
                onSelect={this.onUsersSelect}
                onRemove={this.onUsersRemove}
                showCheckbox={true}
                disablePreSelectedValues={false}
                hidePlaceholder={false}
                selectedValues={this.state.tags}
                /> }

                </div>
                </form>
                : this.props.type === 'counseling' ?
                <form>
                <div className='row'>
                <div className="form-group col-12" >
                <label className="profile-label">Nationality*</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg" name="value1" >
                <option value="">Please Select</option>
                {countries_code.map(head => 
                    <option key={head.country} value={head.value}>{head.country}</option>
                )}
                </select>
                </div>
                <div className="form-group col-12 mt-2">
                <label className="profile-label">Country of Residence (Onshore Students only)</label>
                <select onChange={this.handleChange} className="form-select acc-form form-select-lg" name="value2" >
                <option value="">Please Select</option>
                <option value="australia">Australia</option>
                <option value="canada">Canada</option>
                <option value="uk">United Kingdom</option>
                </select>
                </div>
                </div>
                </form>
                : ''}
                </Modal.Body>  
                <Modal.Footer>  
                {this.props.endButton == true ? "" :
                <Button className="btn btn-danger" onClick={()=>this.handleModal()}>Cancel</Button> } 
                {this.props.submitBtnText ? 
                    <Button className="btn btn-success" onClick={this.handleProceed}>{this.props.submitBtnText}</Button>
                    : 
                    <Button className="btn btn-success" 
                    onClick={
                        this.props.type == 'category' && this.props.from == 'mig' && this.props.appid == 100 && this.props.allowApproval == true  ? this.handleApprovalSubmit : 
                        this.props.type == 'category' && this.props.from == 'edu' && this.props.appid == 100 && this.props.allowApproval == true  ? this.handleEduApprovalSubmit : 
                        this.props.type == 'edit' ? this.handleSubmit : 
                        this.props.type == 'addTeam' || this.props.type == 'assignM' || this.props.type == 'editTeam' ? 
                        this.handleTeamSubmit : 
                        this.props.type == 'category' || this.props.type == 'refund' ||  this.props.type == 'oshc' || this.props.type == 'priority' || this.props.type == 'attendance' || this.props.type == 'expo' ? 
                        this.handleSubmit :
                        this.props.type == 'assignUserToGroup' ? this.handleAssignUserSubmit:
                        this.handlePassSubmit}>
                    {
                    this.props.endButton == true ? 'End Consultation' :
                    this.props.type === 'oshc' && this.props.onlyView == 0  ? 'Apply Refund'  :
                    this.props.type === 'oshc' && this.props.onlyView == 1  ? 'Undo Applied Refund' : 'Update'}</Button>  }
                </Modal.Footer>  
            </Modal>  
          </>
      )
  }
}
export default Modals